<template>
  <div>
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-row>
            <div style="position: fixed; z-index: 1;  background-color: white; width: 100%; top:55px;">
            <v-breadcrumbs :items="items" large style=" font-weight: 600; letter-spacing: 1px; ">

            </v-breadcrumbs>
            </div>
        </v-row><br><br><br />
    <section class="bg-color-">
      <div class="container">
        <template>
          <div v-if="init_loading">
          <v-skeleton-loader v-bind="attrs" type="three-line,  article"></v-skeleton-loader>
        </div>
        <div v-else>
          <v-card>
            <v-card-title>
              <v-autocomplete persistent-hint label="Organization" :items="org_list" item-text="orgName"
                item-value="orgid" v-model="orgid" style="margin-top:10px;"
                @change="getCategorydetails()"></v-autocomplete>
              <v-spacer></v-spacer>
              <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line
                hide-details></v-text-field>
              <template>
                <download-excel :data="details_list" :fields="single_" worksheet="Category Data" name="Category Data.xls">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="green" size="40" left v-bind="attrs" v-on="on"
                        style="margin-left:20px;">mdi-file-excel</v-icon>
                    </template>
                    <span>Import to Excel</span>
                  </v-tooltip>
                </download-excel>
              </template>
            </v-card-title>
       
            <v-data-table :headers="headers" :items="details_list" :search="search">
            </v-data-table>
        
          </v-card>
        </div>
        </template>
      </div>
    </section>
    <!-- /. section -->
  </div>
  <!-- /. root div -->
</template>
<script>
import axios from "axios";
export default {
  components: {},
  data: () => ({
    org_list: [],
    details_list: [],
    snackbar_msg: "",
    color: "",
    snackbar: false,
    init_loading: false,
    search: "",
    orgid: null,
    items: [
      {
        text: 'Dashboard',
        disabled: false,
        href: '/home',
      },
      {
        text: localStorage.getItem("jhhjbyj"),
        disabled: false,
        href: '/sublink',
      },
      {
        text: 'Management Dashboard',
        disabled: false,
        href: '/management-dashboard',
      },
      {
        text: 'Book Categories',
        disabled: true,
        href: '',
      },
    ],
    headers: [
      { text: "Sr. No.", value: "srno" },
      { text: "Book Category", value: "bookCat_name" },

    ],
    single_: {
      "Sr. No.": "srno",
      "Book Category": "bookCat_name",
    },
  }),
  mounted() {
    this.onLoad();
  },
  methods: {
    onLoad() {
      this.init_loading = true;
      axios
        .post("/Management/getAllOrgCategoryData")
        .then((res) => {
          if (res.data.msg == "200") {
            this.init_loading = false;
            this.org_list = res.data.datalist;
            this.orgid = res.data.org;
            this.details_list = res.data.categorylist;
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          window.console.log(error);
          this.init_loading = false;
        });
    },
    getCategorydetails() {
      const data = {
        orgid: this.orgid,
      };
      this.init_loading = true;
      axios
        .post("/Management/getCatDetails", data)
        .then((res) => {
          if (res.data.msg == "200") {
            this.init_loading = false;
            this.details_list = res.data.categorylist;
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          window.console.log(error);
          this.init_loading = false;
        });
    },
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
  },
  watch: {},
};
</script>
<style scoped>
@media (min-width: 320px) and (max-width: 767.98px) {}

@media (min-width: 768px) and (max-width: 991.98px) {}

@media (min-width: 992px) and (max-width: 1024px) {}
</style>
